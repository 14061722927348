var site = site || {};
var generic = generic || {};
(function($, site) {
  site.videos = {
    open: function(opts) {
      var provider = !_.isEmpty(opts.provider) ? opts.provider : '';

      if (provider === 'YouTube') {
        this.openYouTube(opts);
      } else if (provider === 'MP4') {
        this.openHTML5(opts);
      }
    },
    openYouTube: function(opts) {
      var ytId = opts.youTubeId;
      var iframeId = false;
      var context = !_.isEmpty(opts.context) ? opts.context : document;
      var content = '<div class="js-content-block-video-placeholder youtube-video"></div>';
      function createVideoPlayerDiv($elem, i) {
        $elem.removeClass('js-content-block-video-placeholder');
        var id = $elem.attr('class') + '-' + i;
        $elem
          .parent()
          .append('<div />')
          .children('div')
          .attr('id', id);
        return id;
      }

      function addVideo(ytId) {
        /*global YT */
        new YT.Player(iframeId, {
          height: '100%',
          width: '100%',
          videoId: ytId,
          playerVars: {
            autoplay: 1,
            modestbranding: 0,
            controls: 1,
            showinfo: 0,
            rel: 0,
          },
          events: {
            'onReady': function(event) {
              event.target.playVideo();
            }
          }
        });
      }

      var options = {
        content: content,
        height: '100%',
        width: '100%',
        maxWidth: 1024,
        maxHeight: 768,
        className: 'colorbox--video',
        onLoad: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.colorbox--video', context);
          // initially hide close button to get around wonky animation
          $('#cboxClose', _this.$cbox).hide();
        },
        onComplete: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.colorbox--video', context);

          // Create a new div for the player to take over inside of the colorbox
          iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder'), Math.floor(+new Date() / 1000));

          // Remove our empty container
          $('.js-content-block-video-placeholder', _this.$cbox).remove();

          // Instantiate the video
          addVideo(ytId);

          // add container to make video embed responsive
          $('iframe', _this.$cbox).wrap('<div class="embed-container"></div>');

          $.colorbox.resize();

          // show close button once wonky animation ends
          $('#cboxClose', _this.$cbox).show();
        }
      };

      generic.overlay.launch(options);

      $(document).on('videos.pause', function() {
        $('#' + iframeId).each(function() {
          if ($(this).get(0).contentWindow) {
            $(this).get(0).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
          }
        });
      });
    },
    openHTML5: function(opts) {
      var content = '<video controls><source src="' + opts.mp4Source + '" type="video/mp4"></video>';
      var context = !_.isEmpty(opts.context) ? opts.context : document;
      var options = {
        content: content,
        height: 'auto',
        width: '100%',
        maxWidth: 1024,
        maxHeight: 768,
        className: 'colorbox--video',
        onLoad: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.colorbox--video', context);
          // initially hide close button to get around wonky animation
          $('#cboxClose', _this.$cbox).hide();
        },
        onComplete: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.colorbox--video', context);

          // add container to make video embed responsive
          $('video', _this.$cbox).wrap('<div class="embed-container"></div>');

          $.colorbox.resize();

          // show close button once wonky animation ends
          $('#cboxClose', _this.$cbox).show();

          $('video', _this.$cbox).get(0).play();
        }
      };

      generic.overlay.launch(options);

      $(document).on('videos.pause', function() {
        $('video').each(function() {
          $(this).get(0).pause();
        });
      });
    }
  };
})(jQuery, site);
